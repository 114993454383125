import { useState } from "react";
import { Stat } from "../../../types/character-types";

export interface DiceRollProps {
  type: Stat;
  modifier: number;
  dc: number;
  onRollComplete: (result: number, description: string) => void;
}

export const DiceRoll: React.FC<DiceRollProps> = ({
  type,
  modifier,
  dc,
  onRollComplete,
}) => {
  const [result, setResult] = useState<number | null>(null);

  const rollDice = () => {
    const diceRoll = Math.floor(Math.random() * 20) + 1;
    const totalResult = diceRoll + modifier;
    setResult(diceRoll);
    onRollComplete(
      totalResult,
      `Rolled a ${diceRoll} + ${modifier} for a total of ${totalResult} (DC ${dc} ${type
        .toString()
        .toUpperCase()} check)`
    );
  };

  const getRollResultString = (
    result: number | null,
    modifier: number
  ): string => {
    const modifierText =
      modifier > 0
        ? `+ ${modifier} `
        : modifier === 0
        ? ""
        : `- ${Math.abs(modifier)} `;

    return result
      ? `${result} ${modifierText}= ${result + modifier} vs DC ${dc}`
      : `d20 + ${modifier} vs DC ${dc}`;
  };

  return (
    <div className="flex items-center space-x-4 p-4 bg-secondary rounded">
      <div className="text-2xl font-bold">
        {getRollResultString(result, modifier)}
      </div>
      <button className="button" onClick={rollDice} disabled={result !== null}>
        {result ? "Rolled" : "Roll"}
      </button>
      <div className="text-lg">{type.toString().toUpperCase()} check</div>
    </div>
  );
};
