export interface CharacterBackground {
  name: string;
  description: string;
  skills: string[];
}

export const BACKGROUNDS: CharacterBackground[] = [
  {
    name: "Alchemist",
    description:
      "A skilled mixer of potions and elixirs, versed in the art of transmutation.",
    skills: ["Nature", "Crafting", "Poison Knowledge"],
  },
  {
    name: "Blacksmith",
    description:
      "A master of the forge, shaping metal into weapons, armor, and tools.",
    skills: ["Brute Force", "Crafting", "Weapon Knowledge"],
  },
  {
    name: "Carpenter",
    description:
      "A skilled woodworker, able to construct buildings, furniture, and other wooden items.",
    skills: ["Crafting", "Engineering", "Structural Assessment"],
  },
  {
    name: "City Guard",
    description:
      "A keeper of the peace, trained to maintain order and protect citizens.",
    skills: ["Investigation", "Intimidation", "Local Laws"],
  },
  {
    name: "Cobbler",
    description: "A craftsperson skilled in making and repairing footwear.",
    skills: ["Crafting", "Fashion Knowledge", "Persuasion"],
  },
  {
    name: "Cook",
    description:
      "A culinary expert, skilled in preparing meals for nobles and common folk alike.",
    skills: ["Crafting", "Poison Detection", "Nutrition"],
  },
  {
    name: "Diplomat",
    description:
      "A skilled negotiator and representative of political interests.",
    skills: ["Persuasion", "History", "Etiquette"],
  },
  {
    name: "Dockworker",
    description: "A strong laborer experienced in loading and unloading ships.",
    skills: ["Athletics", "Logistics", "Brawling"],
  },
  {
    name: "Entertainer",
    description:
      "A performer skilled in music, acting, or other forms of entertainment.",
    skills: ["Performance", "Acrobatics", "Disguise"],
  },
  {
    name: "Farmer",
    description:
      "A tiller of the land, skilled in growing crops and tending to livestock.",
    skills: ["Survival", "Animal Handling", "Weather Prediction"],
  },
  {
    name: "Fortune Teller",
    description: "A mystic who claims to see the future and interpret omens.",
    skills: ["Deception", "Insight", "Astrology"],
  },
  {
    name: "Gravedigger",
    description:
      "A solemn worker responsible for burying the dead and maintaining cemeteries.",
    skills: ["Athletics", "Anatomy", "Ghost Lore"],
  },
  {
    name: "Herbalist",
    description: "An expert in plants and their medicinal properties.",
    skills: ["Nature", "Medicine", "Poison Crafting"],
  },
  {
    name: "Hunter",
    description:
      "A skilled tracker and marksman, adept at surviving in the wilderness.",
    skills: ["Perception", "Stealth", "Skinning"],
  },
  {
    name: "Innkeeper",
    description:
      "A host and proprietor of a tavern or inn, catering to travelers and locals.",
    skills: ["Persuasion", "Insight", "Local Knowledge"],
  },
  {
    name: "Jester",
    description:
      "A witty entertainer in noble courts, skilled in jokes, pranks, and acrobatics.",
    skills: ["Performance", "Acrobatics", "Court Intrigue"],
  },
  {
    name: "Librarian",
    description:
      "A keeper of books and scrolls, knowledgeable in various subjects.",
    skills: ["History", "Research", "Organization"],
  },
  {
    name: "Merchant",
    description:
      "A savvy trader, experienced in the art of buying, selling, and negotiation.",
    skills: ["Persuasion", "Appraisal", "Geography"],
  },
  {
    name: "Midwife",
    description:
      "A respected member of the community, skilled in childbirth and women's health.",
    skills: ["Medicine", "Persuasion", "Herbalism"],
  },
  {
    name: "Miner",
    description:
      "A hardy worker experienced in extracting valuable minerals from the earth.",
    skills: ["Brute Force", "Geology", "Excavation"],
  },
  {
    name: "Ratcatcher",
    description:
      "A necessary but often overlooked profession, skilled in pest control.",
    skills: ["Animal Handling", "Poison Use", "Urban Navigation"],
  },
  {
    name: "Sailor",
    description:
      "An experienced seafarer, comfortable on ships and knowledgeable about the waters.",
    skills: ["Athletics", "Watercraft", "Rope Use"],
  },
  {
    name: "Scribe",
    description:
      "A learned individual, skilled in reading, writing, and record-keeping.",
    skills: ["History", "Calligraphy", "Research"],
  },
  {
    name: "Shepherd",
    description:
      "A caretaker of flocks, skilled in animal husbandry and wilderness survival.",
    skills: ["Animal Handling", "Survival", "Beast Knowledge"],
  },
  {
    name: "Stablehand",
    description:
      "A worker experienced in caring for horses and maintaining stables.",
    skills: ["Animal Handling", "Athletics", "Veterinary"],
  },
  {
    name: "Street Urchin",
    description:
      "A survivor of the urban underbelly, skilled in navigating city life.",
    skills: ["Sleight of Hand", "Urban Navigation", "Begging"],
  },
  {
    name: "Tailor",
    description: "A skilled craftsperson who makes and alters clothing.",
    skills: ["Crafting", "Fashion Knowledge", "Disguise"],
  },
  {
    name: "Tavern Keeper",
    description:
      "A sociable host, well-versed in serving drinks and managing rowdy patrons.",
    skills: ["Insight", "Brewing", "Banter"],
  },
  {
    name: "Town Crier",
    description:
      "A loud-voiced individual responsible for making public announcements and spreading news.",
    skills: ["Persuasion", "Local Knowledge", "Speed"],
  },
  {
    name: "Woodcutter",
    description:
      "A strong laborer skilled in felling trees and processing timber.",
    skills: ["Athletics", "Survival", "Carpentry"],
  },
];
