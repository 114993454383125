import { Ability } from "./ability-types";
import { CharacterClass } from "./class-types";

export type Dice = {
  sides: number;
};

export type Roll = (Dice | Number)[];

export type ArmorType =
  | "Light Armor"
  | "Medium Armor"
  | "Heavy Armor"
  | "Shield";
export type WeaponType = "Simple Weapon" | "Martial Weapon";

export interface Armor extends Item {
  type: "Armor";
  armorType: ArmorType;
  armorLocation: "Chest" | "Head" | "Legs" | "Feet" | "Arms" | "Hand";
  ac: number;
  modifier: "Dex" | "Dex/2" | "None";
  penalty?: string[];
}

export type WeaponProperties =
  | "Finesse"
  | "Light"
  | "Loading"
  | "Thrown"
  | "Two-Handed"
  | "Versatile";

export type AttackRange = "Close" | "Near" | "Far";

export interface Weapon extends Item {
  type: "Weapon";
  weaponType: WeaponType;
  modType: "Str" | "Dex" | "None" | "Both";
  damage: Roll;
  range: AttackRange;
  properties?: WeaponProperties[];
}

export interface Item {
  name: string;
  equipped: boolean; // false if consumable or other
  type: "Weapon" | "Armor" | "Consumable" | "Other";
  value: number;
  description: string;
  slots: number;
  abilities?: Ability[];
}

export const DEFAULT_INVENTORY: (Weapon | Armor | Item)[] = [
  {
    name: "Longsword",
    type: "Weapon",
    weaponType: "Martial Weapon",
    description:
      "A well-worn blade with a keen edge, perfect for dealing heavy blows in close combat.",
    damage: [{ sides: 8 }],
    range: "Close",
    modType: "Str",
    equipped: true,
    slots: 1,
    value: 90,
  },
  {
    name: "Chainmail Shirt",
    type: "Armor",
    armorType: "Medium Armor",
    armorLocation: "Chest",
    description:
      "Sturdy rings of metal that jingle with each movement, offering solid protection without weighing you down too much.",
    ac: 3,
    modifier: "Dex/2",
    penalty: ["Stealth", "Swim"],
    equipped: true,
    slots: 2,
    value: 600,
  },
  {
    name: "Shield",
    type: "Armor",
    armorType: "Shield",
    armorLocation: "Hand",
    description:
      "A simple but reliable shield, bearing the marks of countless parried blows, ready to defend against even the fiercest attacks.",
    ac: 2,
    modifier: "Dex",
    equipped: true,
    slots: 1,
    value: 100,
  },
  {
    name: "Backpack",
    type: "Other",
    description:
      "A sturdy pack with plenty of room for all your adventuring gear.",
    equipped: false,
    slots: 0,
    value: 20,
  },
];
