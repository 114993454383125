import React from "react";
import { Character } from "../../../types/character-types";

export interface CharacterSheetModalProps {
  character: Character;
}

export const CharacterSheetModal: React.FC<CharacterSheetModalProps> = ({
  character,
}) => {
  const printCharacterSheet = () => {
    window.print();
  };

  return (
    <div className="text-black p-4">
      <style>
        {`
        @media print {
          body * {
            visibility: hidden;
          }
          #printable-area, #printable-area * {
            visibility: visible;
          }
          #printable-area {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
          }
        }
        `}
      </style>
      <div className="flex justify-end mt-2">
        <button className="button print:hidden" onClick={printCharacterSheet}>
          Print
        </button>
      </div>
      <div id="printable-area">
        {/* Character Name Header */}
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-2 text-black">
            {character.name}
          </h1>{" "}
          {/* Moved character name to the top */}
        </div>

        {/* Basic Info and HP/AC/Stats - Compact Layout */}
        <div className="flex justify-between">
          <div className="w-1/2">
            <p>Race: {character.race}</p>
            <p>Class: {character.class}</p>
            <p>Background: {character.background}</p>
            <p>Gender: {character.gender}</p>
            <p>Level: {character.level}</p>
            {/* Hoard - empty and fillable */}
            <p>
              Hoard:{" "}
              <input type="text" className="border-b border-black w-32" />
            </p>
          </div>
          <div className="w-1/2 flex flex-col items-end">
            {/* HP and AC - Updated to have underlines */}
            <p>
              HP:{" "}
              <span className="border-b border-black w-8 inline-block"></span> /{" "}
              {character.hp.max}
            </p>{" "}
            {/* Left the current HP blank */}
            <p>
              AC:{" "}
              <span className="border-b border-black w-8 inline-block"></span>
            </p>{" "}
            {/* Left the AC blank */}
            {/* Stats */}
            <h2 className="text-xl font-bold mt-4">Stats</h2>
            <div className="grid grid-cols-3 gap-2">
              {Object.entries(character.stats).map(([statName, statValue]) => (
                <div key={statName} className="text-center">
                  <p className="font-bold">{statName.toUpperCase()}</p>
                  <p>{statValue >= 0 ? `+${statValue}` : statValue}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Attacks */}
        <h2 className="text-xl font-bold mt-4">Attacks</h2>
        <div className="border border-black h-24"></div>

        {/* Skills */}
        <h2 className="text-xl font-bold mt-4">Skills</h2>
        <ul>
          {character.skills.map((skill, index) => (
            <li key={index}>{skill}</li>
          ))}
        </ul>

        {/* Languages */}
        <h2 className="text-xl font-bold mt-4">Languages</h2>
        <ul>
          {character.languages.map((language, index) => (
            <li key={index}>{language}</li>
          ))}
        </ul>

        {/* Proficiencies */}
        <h2 className="text-xl font-bold mt-4">Proficiencies</h2>
        <ul>
          {character.proficiencies.map((proficiency, index) => (
            <li key={index}>{proficiency}</li>
          ))}
        </ul>

        {/* Inventory */}
        <h2 className="text-xl font-bold mt-4">Inventory</h2>
        <div className="border border-black h-24"></div>

        {/* Abilities */}
        <h2 className="text-xl font-bold mt-4">Abilities</h2>
        {character.abilities.map((ability, index) => (
          <div key={index} className="mb-2">
            <strong>{ability.name}</strong>
            <p>{ability.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
