import { MessageStringParam } from "./game-utils";

export const introFlowStates = [
  "welcome",
  "username",
  "password",
  "worldCreation",
  "classSelection",
  "backgroundSelection",
  "name",
  "statAllocation",
  "gender",
  "height",
  "hair",
  "skinColor",
  "facialAppearance",
  "bodilyAppearance",
  "start",
] as const;

export type IntroFlowState = (typeof introFlowStates)[number];

export const getWelcomeMessage = (): MessageStringParam[] => [
  {
    role: "assistant",
    content:
      "Welcome brave adventurer. I am ZAIN, your wise game master. If you wish to proceed in my fantastic world, please type 'login' or 'signup'.",
  },
];

export const generateCharacterSummary = async (
  characterDescription: string
): Promise<string> => {
  const prompt = `Summarize the following character details into a concise 1-3 sentence description: ${characterDescription}`;
  const systemmessage = `You are a game master summarizing a player's character concisely. 
- Do not prepend or append your description with any additional text.
- Combine various traits into single sentences when it makes sense to do so.
- The character is a standard human, if the user has said non-sensical responses, or has said impossibilities (I am 10 feet tall for example), reduce these traits to standard human traits.
- If the user has not specified certain traits (you might see hair: and then nothing or some random text), you can make up some standard traits for them accordingly.
`;

  const response = await fetch("http://localhost:3000/generate-text", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      prompt,
      systemmessage,
      model: "gpt-4o-mini",
    }),
  });

  const data = await response.json();
  return data.text;
};
