import React from "react";
import { CharacterBackground } from "../../../data/background";

export interface BackgroundModalProps {
  characterBackground: CharacterBackground;
}

export const BackgroundModal: React.FC<BackgroundModalProps> = ({
  characterBackground,
}) => {
  return (
    <div>
      <h1 className="text-black text-center mb-4">
        {characterBackground.name}
      </h1>
      <p className="italic mb-4">{characterBackground.description}</p>
      <p className="mb-4">
        <strong>Skills:</strong> {characterBackground.skills.join(", ")}
      </p>
    </div>
  );
};
