import { Ability } from "./ability-types";
import { Armor, ArmorType, Item, Weapon, WeaponType } from "./inventory-types";

export interface CharacterClass {
  name: string;
  description: string;
  abilities: Ability[];
  hpPerLevel: number;
  proficiency: (WeaponType | ArmorType)[];
  startingEquipment: Item[];
}

const FIGHTER_CLASS: CharacterClass = {
  name: "Fighter",
  description:
    "Masters of martial combat, fighters are unparalleled warriors who excel in both offense and defense. Whether wielding a massive greatsword or deftly maneuvering with sword and shield, fighters are the backbone of any adventuring party.",
  abilities: [
    {
      name: "Hauler",
      description:
        "Add your Constitution modifier, if positive, to your gear slots.",
    },
    {
      name: "Weapon Mastery",
      description:
        "Choose one type of weapon, such as longswords. You gain +1 to attack and damage with that weapon type. In addition, add half your level to these rolls (round down).",
    },
    {
      name: "Grit",
      description:
        "Choose Strength or Dexterity. You have advantage on checks of that type to overcome an opposing force, such as kicking open a stuck door (Strength) or slipping free of rusty chains (Dexterity).",
    },
  ],
  hpPerLevel: 5,
  proficiency: [
    "Martial Weapon",
    "Simple Weapon",
    "Shield",
    "Heavy Armor",
    "Medium Armor",
    "Light Armor",
  ],
  startingEquipment: [
    {
      name: "Longsword",
      type: "Weapon",
      equipped: true,
      weaponType: "Martial Weapon",
      modType: "Str",
      damage: [{ sides: 8 }],
      range: "Close",
      value: 90,
      description:
        "A well-worn blade with a keen edge, perfect for dealing heavy blows in close combat.",
      slots: 1,
    } as Weapon,
    {
      name: "Chainmail Shirt",
      equipped: true,
      type: "Armor",
      armorType: "Medium Armor",
      armorLocation: "Chest",
      value: 600,
      description:
        "Sturdy rings of metal that jingle with each movement, offering solid protection without weighing you down too much.",
      slots: 2,
      ac: 3,
      modifier: "Dex/2",
      penalty: ["Stealth", "Swim"],
    } as Armor,
    {
      name: "Shield",
      equipped: true,
      type: "Armor",
      armorType: "Shield",
      armorLocation: "Hand",
      value: 100,
      description:
        "A simple but reliable shield, bearing the marks of countless parried blows, ready to defend against even the fiercest attacks.",
      modifier: "Dex",
      ac: 2,
      slots: 1,
    } as Armor,
    {
      name: "Backpack",
      equipped: false,
      type: "Other",
      value: 20,
      description:
        "A sturdy pack with plenty of room for all your adventuring gear.",
      slots: 0,
    } as Item,
  ],
};

const PRIEST_CLASS: CharacterClass = {
  name: "Priest",
  description:
    "Crusading templars, prophetic shamans, or mad-eyed zealots who wield the power of their gods to cleanse the unholy.",
  abilities: [
    {
      name: "Turn Undead",
      description:
        "You know the Turn Undead spell. It doesn’t count toward your number of known spells.",
    },
    {
      name: "Deity",
      description:
        "Choose a god to serve who matches your alignment. You have a holy symbol for your god (it takes up no gear slots).",
    },
    {
      name: "Spellcasting",
      description:
        "You can cast priest spells you know. You know two tier 1 spells of your choice from the priest spell list. Each time you gain a level, you choose new priest spells to learn.",
    },
  ],
  hpPerLevel: 6,
  proficiency: [
    "Light Armor",
    "Medium Armor",
    "Heavy Armor",
    "Shield",
    "Simple Weapon",
    "Martial Weapon",
  ],
  startingEquipment: [],
};

const THIEF_CLASS: CharacterClass = {
  name: "Thief",
  description:
    "Rooftop assassins, grinning con artists, or cloaked cat burglars who can pluck a gem from the claws of a sleeping demon and sell it for twice its worth.",
  abilities: [
    {
      name: "Backstab",
      description:
        "If you hit a creature who is unaware of your attack, you deal an extra weapon die of damage. Add additional weapon dice of damage equal to half your level (round down).",
    },
    {
      name: "Thievery",
      description:
        "You are adept at thieving skills and have the necessary tools of the trade secreted on your person (they take up no gear slots). You are trained in climbing, sneaking, applying disguises, finding and disabling traps, and picking pockets or locks. You have advantage on checks related to these tasks.",
    },
  ],
  hpPerLevel: 4,
  proficiency: ["Light Armor", "Simple Weapon", "Martial Weapon"],
  startingEquipment: [],
};

const WIZARD_CLASS: CharacterClass = {
  name: "Wizard",
  description:
    "Rune-tattooed adepts, bespectacled magi, and flame-conjuring witches who dare to manipulate the fell forces of magic.",
  abilities: [
    {
      name: "Learning Spells",
      description:
        "You can permanently learn a wizard spell from a spell scroll by studying it for a day and succeeding on a DC 15 Intelligence check. Whether you succeed or fail, you expend the spell scroll.",
    },
    {
      name: "Spellcasting",
      description:
        "You can cast wizard spells you know. You know three tier 1 spells of your choice from the wizard spell list. Each time you gain a level, you choose new wizard spells to learn.",
    },
    {
      name: "Languages",
      description:
        "You know two additional common languages and two rare languages.",
    },
  ],
  hpPerLevel: 4,
  proficiency: ["Simple Weapon"],
  startingEquipment: [],
};

export const CLASSES: CharacterClass[] = [
  FIGHTER_CLASS,
  PRIEST_CLASS,
  THIEF_CLASS,
  WIZARD_CLASS,
];

export type ClassTypes = "fighter";
