import React, { useState } from "react";
import { Character } from "../../../types/character-types";
import {
  BANES,
  CLASS_BOONS,
  GENERAL_BOONS,
  LANGUAGE_BOONS,
  LevelAbility,
  RACES,
  SKILL_BOONS,
  TIERED_GENERAL_BOONS,
} from "../../../types/ability-types";
import { isPresent } from "../../../utils/utility";

export interface LevelUpModalProps {
  character: Character;
  updateCharacter: (key: keyof Character, value: any) => void;
}

const CollapsibleSection: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className="mb-4 pl-0">
      <div
        className="flex items-center cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className="mr-2 w-4 text-left">{isExpanded ? "▼" : "▶"}</span>
        <h3 className="text-black font-bold text-xl">{title}</h3>
      </div>
      {isExpanded && <div className="mt-2">{children}</div>}
    </div>
  );
};

const AbilityItem: React.FC<{
  ability: LevelAbility;
  character: Character;
  updateCharacter: (key: keyof Character, value: any) => void;
  canPurchase: boolean;
}> = ({ ability, character, updateCharacter, canPurchase }) => {
  const [expanded, setExpanded] = useState(false);

  const purchaseAbility = (ability: LevelAbility) => {
    const updatedAbilities = [...character.abilities, ability];
    updateCharacter("abilities", updatedAbilities);

    const updatedCP = character.character_points;
    updatedCP.used += ability.cost;
    updateCharacter("character_points", updatedCP);

    if (isPresent(ability.skills) && ability.skills.length > 0) {
      const updatedSkills = [...character.skills, ...ability.skills];
      updateCharacter("skills", updatedSkills);
    }

    if (isPresent(ability.languages) && ability.languages.length > 0) {
      const updatedLanguages = [...character.languages, ...ability.languages];
      updateCharacter("languages", updatedLanguages);
    }

    if (ability.type == "race") {
      updateCharacter("race", ability.name);
    }
  };

  return (
    <div className="modal mb-4 p-2 pl-6 border rounded">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <h3 className="text-lg font-bold text-black">{ability.name}</h3>
        <span>{ability.cost} CP</span>
      </div>
      {expanded && (
        <div className="mt-2">
          <p className="whitespace-pre-wrap">{ability.description}</p>
          <button
            className={`mt-2 px-4 py-2 rounded ${
              canPurchase
                ? "bg-blue-500 text-white"
                : "bg-gray-300 text-gray-500"
            }`}
            onClick={() => purchaseAbility(ability)}
            disabled={!canPurchase}
          >
            Purchase
          </button>
        </div>
      )}
    </div>
  );
};

export const LevelUpModal: React.FC<LevelUpModalProps> = ({
  character,
  updateCharacter,
}) => {
  const hasPurchasedAbility = (abilityName: string) =>
    character.abilities.some((ability) => ability.name === abilityName);

  const getPurchasedTier = (abilityName: string) => {
    const purchasedAbility = character.abilities.find((ability) =>
      ability.name.startsWith(abilityName)
    );
    console.log("PurchasedAbility", purchasedAbility);
    return purchasedAbility ? purchasedAbility.tier : 0;
  };

  const availableGeneralBoons = [
    ...GENERAL_BOONS.filter((boon) => !hasPurchasedAbility(boon.name)),
    ...TIERED_GENERAL_BOONS.flatMap((tieredBoon) => {
      const purchasedTier = getPurchasedTier(tieredBoon.name) ?? 0;
      console.log("purchasedTier", purchasedTier);
      return tieredBoon.tiers
        .filter((boon) => !hasPurchasedAbility(boon.name))
        .filter((tier) => tier.tier ?? 0 > purchasedTier)
        .map((tier) => ({
          ...tier,
          cost:
            (tier.tier ?? 0) > 1 && (tier.tier ?? 0) === purchasedTier + 1
              ? tier.cost - (tieredBoon.tiers[purchasedTier - 1]?.cost ?? 0)
              : tier.cost,
        }));
    }),
  ];

  const availableClassBoons =
    character.class && CLASS_BOONS[character.class]
      ? CLASS_BOONS[character.class].filter(
          (boon) => !hasPurchasedAbility(boon.name)
        )
      : [];

  const availableSkillBoons = SKILL_BOONS.filter(
    (boon) => !hasPurchasedAbility(boon.name)
  );

  const availableLanguageBoons = LANGUAGE_BOONS.map((languageBoon) => {
    const purchasedTier = getPurchasedTier(languageBoon.name) ?? 0;
    return {
      ...languageBoon,
      tiers: languageBoon.tiers
        .filter((boon) => !hasPurchasedAbility(boon.name))
        .filter((tier) => {
          const tierAlreadyPurchased = character.abilities.some(
            (ability) => ability.name === tier.name
          );
          return tier.tier ?? (0 > purchasedTier && !tierAlreadyPurchased);
        }),
    };
  }).filter((languageBoon) => languageBoon.tiers.length > 0);

  const availableBanes = BANES.filter(
    (bane) => !hasPurchasedAbility(bane.name)
  );

  return (
    <div>
      <h1 className="text-black text-center mb-4">Boons and Banes</h1>
      <p className="mb-4">
        <b>Current Level:</b> {character.level}{" "}
      </p>
      <p className="mb-4">
        <b>Available Character Points:</b>{" "}
        {character.character_points.total - character.character_points.used} /{" "}
        {character.character_points.total}{" "}
      </p>
      <div className="mb-4">
        <h2 className="text-black font-bold text-2xl">Boons</h2>
        <CollapsibleSection title="General Boons">
          {availableGeneralBoons.map((boon) => (
            <AbilityItem
              key={boon.name}
              ability={boon}
              character={character}
              updateCharacter={updateCharacter}
              canPurchase={
                character.character_points.total -
                  character.character_points.used >=
                boon.cost
              }
            />
          ))}
        </CollapsibleSection>

        {isPresent(character.class) && availableClassBoons.length > 0 && (
          <CollapsibleSection title="Class Boons">
            {availableClassBoons.map((boon) => (
              <AbilityItem
                key={boon.name}
                ability={boon}
                character={character}
                updateCharacter={updateCharacter}
                canPurchase={
                  character.character_points.total -
                    character.character_points.used >=
                  boon.cost
                }
              />
            ))}
          </CollapsibleSection>
        )}

        <CollapsibleSection title="Skill Boons">
          {availableSkillBoons.map((boon) => (
            <AbilityItem
              key={boon.name}
              ability={boon}
              character={character}
              updateCharacter={updateCharacter}
              canPurchase={
                character.character_points.total -
                  character.character_points.used >=
                boon.cost
              }
            />
          ))}
        </CollapsibleSection>

        <CollapsibleSection title="Language Boons">
          {availableLanguageBoons.map((languageBoon) =>
            languageBoon.tiers.map((tier) => (
              <AbilityItem
                key={tier.name}
                ability={tier}
                character={character}
                updateCharacter={updateCharacter}
                canPurchase={
                  character.character_points.total -
                    character.character_points.used >=
                  tier.cost
                }
              />
            ))
          )}
        </CollapsibleSection>

        {character.race === "Human" && (
          <CollapsibleSection title="Races">
            {RACES.map((race) => (
              <AbilityItem
                key={race.name}
                ability={race}
                character={character}
                updateCharacter={updateCharacter}
                canPurchase={
                  character.character_points.total -
                    character.character_points.used >=
                  race.cost
                }
              />
            ))}
          </CollapsibleSection>
        )}
      </div>
      <div className="mb-4">
        <h2 className="text-black font-bold text-2xl">Banes</h2>
        {availableBanes.map((bane) => (
          <AbilityItem
            key={bane.name}
            ability={bane}
            character={character}
            updateCharacter={updateCharacter}
            canPurchase={
              character.character_points.total -
                character.character_points.used >=
              bane.cost
            }
          />
        ))}
      </div>
    </div>
  );
};
