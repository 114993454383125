import React from "react";
import { CharacterClass } from "../../../types/class-types";

export interface ClassModalProps {
  characterClass: CharacterClass;
}

export const ClassModal: React.FC<ClassModalProps> = ({ characterClass }) => {
  return (
    <div>
      <h1 className="text-black text-center mb-4">{characterClass.name}</h1>
      <p className="italic mb-4">{characterClass.description}</p>
      <p className="mb-2">
        <strong>Hit Points Per Level:</strong> {characterClass.hpPerLevel}
      </p>
      <p className="mb-4">
        <strong>Proficiencies:</strong> {characterClass.proficiency.join(", ")}
      </p>
      <h2 className="text-black text-left">Abilities</h2>
      {characterClass.abilities.map((ability, index) => (
        <p key={index} className="mb-2">
          <strong>{ability.name}:</strong> {ability.description}
        </p>
      ))}
      <h2 className="text-black text-left">Starting Equipment</h2>
      <ul>
        {characterClass.startingEquipment.map((item, index) => (
          <li key={index}>{item.name}</li>
        ))}
      </ul>
    </div>
  );
};
