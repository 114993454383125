import React, { useState, useEffect, useRef } from "react";
import {
  TerminalEntry,
  TerminalEntryProps,
} from "./components/terminal-entry/TerminalEntry";
import { isPresent } from "./utils/utility";
import {
  generateCharacterSummary,
  getWelcomeMessage,
  IntroFlowState,
  introFlowStates,
} from "./utils/intro-utils";
import {
  Character,
  DEFAULT_CHARACTER,
  isValidStat,
  setCharacterBackground,
  setCharacterStats,
  Stat,
} from "./types/character-types";
import { setCharacterClass } from "./types/character-types";
import { MAX_CHAT_LENGTH } from "./data/chat";
import { handleUserInputForGame, initializeWorld } from "./utils/game-utils";
import { initializeCharacter } from "./types/character-types";
import { CharacterClass, CLASSES } from "./types/class-types";
import { useCallback } from "react";
import { BACKGROUNDS } from "./data/background";
import { StatValue } from "./components/terminal-entry/stat-allocation/StatAllocation";
import { DEFAULT_INVENTORY, Item } from "./types/inventory-types";
import { SidePanel } from "./components/side-panel/SidePanel";
import { Modal } from "./components/modal/Modal";

let socket: WebSocket | null;

export const App: React.FC = () => {
  const [entries, setEntries] = useState<TerminalEntryProps[]>([]);
  const [userInput, setUserInput] = useState<string>("");
  const [flowState, setFlowState] = useState<IntroFlowState | "game">(
    "welcome"
  );
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const terminalRef = useRef<HTMLDivElement>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const isGeneratingImageRef = useRef(false);
  const [selectedClass, setSelectedClass] = useState<string | null>(null);
  const [selectedBackground, setSelectedBackground] = useState<string | null>(
    null
  );
  const [pendingRoll, setPendingRoll] = useState<{
    type: Stat;
    dc: number;
  } | null>(null);
  const [sidePanelWidth, setSidePanelWidth] = useState(400);
  const [showConsoleModal, setShowConsoleModal] = useState(false);
  const [showCharacterSheetModal, setShowCharacterSheetModal] = useState(false);
  console.log("flow", flowState);
  // Game State
  const [character, setCharacter] = useState<Character>(initializeCharacter);
  const [inventory, setInventory] = useState<Item[]>([]);
  const [world, setWorld] = useState(initializeWorld);

  // logging in stuff
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [authMode, setAuthMode] = useState<"login" | "signup" | null>(null);

  interface ImageRequestBody {
    text: string;
  }

  const generateImage = useCallback(async (text: string) => {
    if (isGeneratingImageRef.current) return;
    isGeneratingImageRef.current = true;

    try {
      const response = await fetch("/generate-image", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text }),
      });
      const data = await response.json();
      console.log("done generating");
      console.log("this is the url: " + data.url);
      setImageUrl(data.url);
    } catch (error) {
      console.error("Failed to generate image:", error);
    }

    isGeneratingImageRef.current = false;
  }, []);

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");

    if (userToken) {
      setFlowState("worldCreation");
      setEntries((prevEntries) => [
        {
          role: "assistant",
          content: "What would you like to call your world?",
        },
      ]);
    } else {
      setFlowState("welcome");
      setEntries(getWelcomeMessage());
    }

    const connectWebSocket = () => {
      socket = new WebSocket("ws://localhost:8080");

      socket.onopen = () => {
        console.log("WebSocket connection established");
      };

      socket.onerror = (error) => {
        console.error("WebSocket Error:", error);
      };

      socket.onmessage = (event: MessageEvent) => {
        const data = event.data;

        if (typeof data === "string") {
          try {
            const parsedData = JSON.parse(data);
            console.log("parsedData");
            if (parsedData.type === "roll_request") {
              setIsStreaming(false);
              console.log("roll data", parsedData);
              const rollInfo = parsedData.rollInfo;
              const stat = rollInfo.type;
              const dc = rollInfo.dc;
              if (isValidStat(stat)) {
                setPendingRoll({ type: stat, dc });
                setEntries((prevEntries) => [
                  ...prevEntries,
                  {
                    role: "assistant",
                    content: parsedData.rollInfo.explanation,
                  },
                  {
                    role: "user",
                    content: `Rolling a DC ${dc} ${stat.toUpperCase()} check`,
                    type: "diceRoll",
                    diceRollProps: {
                      type: stat,
                      modifier: rollInfo.modifier,
                      dc: dc,
                      onRollComplete: (result, description) =>
                        onDiceRollComlete(result, description, stat, dc),
                    },
                  },
                ]);
              }
            } else if (parsedData.type === "effect_request") {
              if (parsedData.effect.playerDamage) {
                setCharacter((prevCharacter) => ({
                  ...prevCharacter,
                  hp: {
                    ...prevCharacter.hp,
                    current: Math.max(
                      0,
                      prevCharacter.hp.current - parsedData.effect.playerDamage
                    ),
                  },
                }));
              }
            } else if (parsedData.type === "message_stop") {
              setIsStreaming(false);

              setEntries((prevEntries) => {
                const updatedEntries = [...prevEntries];
                const lastEntryIndex = updatedEntries.length - 1;

                if (lastEntryIndex >= 1) {
                  const lastEntry = updatedEntries[lastEntryIndex]?.content;

                  if (lastEntry) {
                    console.log("generating image");
                    generateImage(lastEntry);
                  } else {
                    console.error(
                      "No valid text available for image generation"
                    );
                  }
                } else {
                  console.error("Not enough entries to generate an image");
                }

                return updatedEntries;
              });
            }
          } catch (error) {
            setEntries((prevEntries) => {
              const updatedEntries = [...prevEntries];
              const lastEntryIndex = updatedEntries.length - 1;
              const lastEntry = updatedEntries[lastEntryIndex];

              if (lastEntry && lastEntry.role === "assistant") {
                updatedEntries[lastEntryIndex] = {
                  ...lastEntry,
                  content: lastEntry.content + data,
                };
              } else {
                updatedEntries.push({
                  role: "assistant",
                  content: data,
                });
              }
              return updatedEntries;
            });
          }
        }
      };

      socket.onclose = (event) => {
        console.log("WebSocket connection closed", event);
        setTimeout(() => {
          console.log("Reconnecting...");
          connectWebSocket();
        }, 3000);
      };
    };

    connectWebSocket();

    return () => {
      if (socket) socket.close();
    };
  }, [generateImage]);

  useEffect(() => {
    if (terminalRef.current) {
      const { scrollHeight, clientHeight } = terminalRef.current;
      terminalRef.current.scrollTop = scrollHeight - clientHeight;
    }
  }, [entries, character.stats]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        (event.metaKey || event.ctrlKey) &&
        event.shiftKey &&
        event.key === "O"
      ) {
        event.preventDefault();
        setShowConsoleModal(true);
      }
      if (
        (event.metaKey || event.ctrlKey) &&
        event.shiftKey &&
        event.key === "L"
      ) {
        event.preventDefault();
        setShowCharacterSheetModal(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleUserInput = () => {
    if (!userInput.trim() || isStreaming) return;
    if (flowState === "game") {
      handleUserInputForGame(
        userInput,
        entries,
        setEntries,
        setIsStreaming,
        socket,
        MAX_CHAT_LENGTH,
        isPresent
      );
    } else if (introFlowStates.includes(flowState)) {
      handleIntroFlow();
    }

    setUserInput("");
    if (flowState === "game") {
      setIsStreaming(true);
    } else {
      setIsStreaming(false);
    }
  };

  const handleUndo = () => {
    setIsStreaming(false);
    setPendingRoll(null);
    setEntries((prevEntries) => {
      if (prevEntries.length >= 1) {
        return prevEntries.slice(0, -1);
      }
      return prevEntries;
    });
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const updateCharacter = (key: keyof Character, value: any) => {
    setCharacter((prevCharacter) => ({
      ...prevCharacter,
      [key]: value,
    }));
  };

  const handleIntroFlow = async () => {
    if (flowState === "welcome") {
      if (userInput.toLowerCase() === "login") {
        setAuthMode("login");
        setEntries((prevEntries) => [
          ...prevEntries,
          { role: "user", content: userInput },
          { role: "assistant", content: "Please enter your username." },
        ]);
        setFlowState("username");
      } else if (userInput.toLowerCase() === "signup") {
        setAuthMode("signup");
        setEntries((prevEntries) => [
          ...prevEntries,
          { role: "user", content: userInput },
          { role: "assistant", content: "Please enter a new username." },
        ]);
        setFlowState("username");
      } else {
        setEntries((prevEntries) => [
          ...prevEntries,
          { role: "user", content: userInput },
          {
            role: "assistant",
            content: "Please type 'login' or 'signup' to proceed.",
          },
        ]);
      }
    } else if (flowState === "username") {
      setUsername(userInput);
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        { role: "assistant", content: "Please enter your password." },
      ]);
      setFlowState("password");
    } else if (flowState === "password") {
      setPassword(userInput);
      try {
        const endpoint = authMode === "login" ? "/login" : "/signup";
        const response = await fetch(`http://localhost:3000${endpoint}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, password: userInput }),
        });
        const data = await response.json();

        if (response.ok && data.token) {
          localStorage.setItem("userToken", data.token);
          setEntries((prevEntries) => [
            ...prevEntries,
            { role: "user", content: "*****" },
            {
              role: "assistant",
              content:
                authMode === "login"
                  ? "Login successful. Welcome back!"
                  : "Signup successful. Welcome to your world creation!",
            },
          ]);
          setFlowState("worldCreation");
        } else {
          setEntries((prevEntries) => [
            ...prevEntries,
            { role: "user", content: "*****" },
            {
              role: "assistant",
              content: `Authentication failed: ${data.message}. Please try again.`,
            },
          ]);
          setFlowState("welcome");
        }
      } catch (error) {
        console.error("Authentication Error:", error);
        setEntries((prevEntries) => [
          ...prevEntries,
          { role: "user", content: "*****" },
          {
            role: "assistant",
            content:
              "No associated account. Please reach out to me@playzain.com for closed alpha access. Get excited for an endless adventure in your own fantasy world! Build relationships with npcs. Conquer dangerous dungeons. Cross realms. Defeat monsters. Build your own realm. Do anything you want. ",
          },
        ]);
        setFlowState("welcome");
      }
      setAuthMode(null);
    } else if (flowState === "worldCreation") {
      if (userInput.toLowerCase() === "!default") {
        setCharacter(DEFAULT_CHARACTER);
        setInventory(DEFAULT_INVENTORY);
        setWorld((prevWorld) => ({ ...prevWorld, name: "Exandria" }));
        clearEntries();
        setEntries([
          {
            role: "assistant",
            content:
              "Welcome Adrian! You are standing outside of a dungeon. What do you do?",
          },
        ]);
        setFlowState("game");
      } else {
        setWorld((prevWorld) => ({ ...prevWorld, name: userInput }));
        setEntries((prevEntries) => [
          ...prevEntries,
          { role: "user", content: userInput },
          {
            role: "assistant",
            content: "Please select a class:",
            type: "classSelection",
            modalSelectorProps: {
              type: "classSelection",
              options: CLASSES.map((cls) => cls.name),
              onSelect: (sel) => handleSelection(sel, "classSelection"),
              selectedOption: selectedClass,
            },
          },
        ]);
        setFlowState("classSelection");
      }
    } else if (flowState === "classSelection") {
      // handled by handleSelection
    } else if (flowState === "backgroundSelection") {
      // handled by handleSelection
    } else if (flowState === "name") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        name: userInput,
        description: `Name: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        {
          role: "assistant",
          content: "Now, let's allocate your character's stats:",
          type: "statAllocation",
          statAllocationProps: {
            onComplete: handleStatAllocationComplete,
          },
        },
      ]);
      setFlowState("statAllocation");
    } else if (flowState === "statAllocation") {
      // handled by handleStatAllocation
    } else if (flowState === "gender") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: prevCharacter.description + `\nGender: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        { role: "assistant", content: "Please enter your character's height:" },
      ]);
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: prevCharacter.description + `\nGender: ${userInput}`,
      }));
      setFlowState("height");
    } else if (flowState === "height") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: prevCharacter.description + `\nHeight: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        {
          role: "assistant",
          content: "Please enter your character's hair color:",
        },
      ]);
      setFlowState("hair");
    } else if (flowState === "hair") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: prevCharacter.description + `\nHair Color: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        {
          role: "assistant",
          content: "Please enter your character's skin color:",
        },
      ]);
      setFlowState("skinColor");
    } else if (flowState === "skinColor") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: prevCharacter.description + `\nSkin Color: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        {
          role: "assistant",
          content: "Please enter your character's facial appearance:",
        },
      ]);
      setFlowState("facialAppearance");
    } else if (flowState === "facialAppearance") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description:
          prevCharacter.description + `\nFacial Appearance: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        {
          role: "assistant",
          content: "Please enter your character's bodily appearance:",
        },
      ]);
      setFlowState("bodilyAppearance");
    } else if (flowState === "bodilyAppearance") {
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
      ]);

      const summary = await generateCharacterSummary(
        character.description + `\nBodily Appearance: ${userInput}`
      );
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: summary,
      }));

      clearEntries();
      setEntries([
        {
          role: "assistant",
          content:
            "Congratulations, your character is complete! You are standing outside of a dungeon. What do you do?",
        },
      ]);
      setFlowState("game");
    }
  };

  const handleSelection = (selection: string, selectionType: string) => {
    if (selectionType === "classSelection") {
      const selectedClass = CLASSES.find((cls) => cls.name === selection);
      if (isPresent(selectedClass)) {
        setCharacter((prevCharacter) =>
          setCharacterClass(prevCharacter, selectedClass as CharacterClass)
        );
        setInventory(selectedClass?.startingEquipment || []);
        setSelectedClass(selection);
        setEntries((prevEntries) => [
          ...prevEntries,
          { role: "user", content: `${selection} selected` },
          {
            role: "assistant",
            content: "Please select a background:",
            type: "backgroundSelection",
            modalSelectorProps: {
              type: "backgroundSelection",
              options: BACKGROUNDS.map((cls) => cls.name),
              onSelect: (sel) => handleSelection(sel, "backgroundSelection"),
              selectedOption: selectedBackground,
            },
          },
        ]);
        setFlowState("backgroundSelection");
      }
    } else if (selectionType === "backgroundSelection") {
      setCharacter((prevCharacter) =>
        setCharacterBackground(prevCharacter, selection)
      );
      setSelectedBackground(selection);
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: `${selection} selected` },
        { role: "assistant", content: "Please enter your character's name:" },
      ]);
      setFlowState("name");
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const handleStatAllocationComplete = (stats: StatValue[]) => {
    setCharacter((prevCharacter) =>
      setCharacterStats(prevCharacter, stats, inventory)
    );
    setEntries((prevEntries) => [
      ...prevEntries,
      { role: "user", content: "Stats allocated" },
      {
        role: "assistant",
        content: "Great! Now, please enter your character's gender:",
      },
    ]);
    setFlowState("gender");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const getInputPlaceholder = () => {
    if (isStreaming) return "Please wait...";
    if (pendingRoll !== null) return "Please roll...";
    switch (flowState) {
      case "classSelection":
        return "Please select a class...";
      case "backgroundSelection":
        return "Please select a background...";
      case "statAllocation":
        return "Please allocate your stats...";
      default:
        return "Type here...";
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const clearEntries = () => {
    setEntries([]);
  };

  const sendRollResult = (result: number, stat: Stat, dc: number) => {
    if (socket) {
      const recentMessages = entries.slice(-5).map((entry) => ({
        role: entry.role,
        content: entry.content,
      }));

      const metadata = {
        previousRoll: {
          dc,
          roll: result,
          stat,
        },
      };

      console.log("Recent Messages", recentMessages);

      socket.send(
        JSON.stringify({
          messages: recentMessages,
          analysisInfo: metadata,
        })
      );
    }
  };

  const onDiceRollComlete = (
    result: number,
    description: string,
    type: Stat,
    dc: number
  ) => {
    sendRollResult(result, type, dc);
    setPendingRoll(null);
  };

  return (
    <main className="flex h-screen bg-background text-text font-mono">
      <div className="flex-1 flex flex-col justify-start mr-8 p-8 min-w-[200px]">
        <div
          className="overflow-y-auto flex-grow scrollbar-hide"
          ref={terminalRef}
        >
          {entries.map((entry, index) => (
            <TerminalEntry
              key={index}
              content={entry.content}
              role={entry.role}
              type={entry.type}
              modalSelectorProps={entry.modalSelectorProps}
              statAllocationProps={entry.statAllocationProps}
              diceRollProps={entry.diceRollProps}
            />
          ))}
        </div>
        <div className="mt-auto w-full">
          <input
            className="w-full bg-background text-text border-none outline-none font-mono text-xl"
            ref={inputRef}
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleUserInput()}
            placeholder={getInputPlaceholder()}
            disabled={
              isStreaming ||
              flowState === "classSelection" ||
              flowState === "backgroundSelection" ||
              flowState === "statAllocation" ||
              pendingRoll !== null
            }
          />
        </div>
      </div>
      <SidePanel
        width={sidePanelWidth}
        setWidth={setSidePanelWidth}
        imageUrl={imageUrl || "/images/zain-title.png"}
        character={character}
        inventory={inventory}
        updateCharacter={updateCharacter}
        onUndo={handleUndo}
      />
      {showConsoleModal && (
        <Modal
          onClose={() => setShowConsoleModal(false)}
          consoleModalProps={{
            character,
            updateCharacter,
          }}
        />
      )}
      {showCharacterSheetModal && (
        <Modal
          onClose={() => setShowCharacterSheetModal(false)}
          characterSheetModalProps={{
            character,
          }}
        />
      )}
    </main>
  );
};

export default App;
