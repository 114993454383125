export interface Ability {
  name: string;
  description: string;
  skills?: string[];
  languages?: string[];
  usesPerDay?: {
    used: number;
    total: number;
  };
  usesEver?: {
    used: number;
    total: number;
  };
  tier?: number;
}

export interface LevelAbility extends Ability {
  cost: number;
  type: "general" | "class" | "race" | "bane" | "language" | "skill";
}

export interface TieredAbility {
  name: string;
  description: string;
  tiers: LevelAbility[];
}

export const GENERAL_BOONS: LevelAbility[] = [
  {
    name: "Actor",
    description:
      "You have advantage on Charisma checks when trying to pass yourself off as a different person.",
    cost: 3,
    type: "general",
  },
  {
    name: "Alert",
    description: "You can’t be surprised while you are conscious.",
    cost: 6,
    type: "general",
  },
  {
    name: "Amphibious",
    description: "You can breathe underwater.",
    cost: 6,
    type: "general",
  },
  {
    name: "Chef",
    description:
      "If you prepare food for the party, upon a successful DC 10 Wisdom check, your party gains the full benefits of a rest in 8 hours instead of 10.\nYou must describe in detail the meal you prepared for the party. On an extra roll on your rations die, you can grant one other party member a luck token.",
    cost: 6,
    type: "general",
  },
  {
    name: "Durable Magic",
    description:
      "After initially casting a concentration spell, add 1d6 to any additional concentration checks.",
    cost: 8,
    type: "general",
  },
  {
    name: "Eyes of the Familiar",
    description:
      "If you have a pet or familiar, you can see through their eyes at will (cannot see through your own, but can hear or be shaken).",
    cost: 8,
    type: "general",
  },
  {
    name: "Familiar Spellsource",
    description:
      "If you have a strong bond with an animal, you can cast spells from them.",
    cost: 6,
    type: "general",
  },
  {
    name: "Flaming Weapon",
    description:
      "Roll Wisdom check to light a melee weapon on fire. Can do this until the check is failed, in which case you need to rest.\n- Will stay on fire for 2d6 rounds.\n- Extra damage dependent on DC:\n  - DC 10: +1\n  - DC 12: +1d4\n  - DC 14: +1d6\n  - DC 16: +1d8\n  - DC 18: +1d10\n  - DC 20: +1d12",
    cost: 12,
    type: "general",
  },
  {
    name: "Generous",
    description:
      "You can bestow a luck token you earned onto another player’s roll.",
    cost: 3,
    type: "general",
  },
  {
    name: "Great Weapon Fighting",
    description:
      "When you roll a 1 or 2 on a damage die for an attack you make with a melee weapon that you are wielding with two hands, you can reroll the die and must use the new roll, even if the new roll is a 1 or a 2.\nThe weapon must have the two-handed or versatile property for you to gain this benefit.",
    cost: 8,
    type: "general",
  },
  {
    name: "Haggler",
    description: "When you sell a treasure, roll 1d100 + 100 (instead of +50).",
    cost: 3,
    type: "general",
  },
  {
    name: "Hover",
    description: "You can hover.",
    cost: 6,
    type: "general",
  },
  {
    name: "Iron Gut",
    description:
      "You have advantage on Constitution checks against being poisoned.\nYou also have advantage on checks to forage for food for yourself (not picky about what you eat).",
    cost: 3,
    type: "general",
  },
  {
    name: "Mage Bumrush",
    description:
      "You have advantage on checks against spells cast within 5 feet of you.",
    cost: 4,
    type: "general",
  },
  {
    name: "Loading Expert",
    description:
      "You do not have to spend your movement to reload for one weapon with the loading property.",
    cost: 8,
    type: "general",
  },
  {
    name: "Reckless Charger",
    description:
      "If you do not have any allies near to you, gain advantage on melee attacks and deal +2 damage.",
    cost: 8,
    type: "general",
  },
  {
    name: "Roadhog",
    description:
      "If using a whip or razor chains, make a contesting Strength check with an opponent you have successfully hit to pull them 10 feet toward you.",
    cost: 8,
    type: "general",
  },
  {
    name: "Signature Spell",
    description: "Choose one spell. Add a d6 whenever you cast it.",
    cost: 8,
    type: "general",
  },
  {
    name: "Soldier",
    description:
      "When engaged in melee combat with an enemy, if an ally is also engaged in melee combat with the same enemy (standing close and actively fighting), you have advantage on the attack and deal +2 damage.",
    cost: 8,
    type: "general",
  },
  {
    name: "Summon Mount",
    description: "3/day, you can summon your mount to your location.",
    cost: 8,
    type: "general",
  },
  {
    name: "Vagrant",
    description:
      "You are used to sleeping outside without the comfort of homes. Rest outside without a bedroll without needing to make a Constitution check.",
    cost: 1,
    type: "general",
  },
  {
    name: "Wings",
    description: "You have a pair of wings and can fly.",
    cost: 10,
    type: "general",
  },
];

export const TIERED_GENERAL_BOONS: TieredAbility[] = [
  {
    name: "High Ground Controller",
    description: "You gain extra benefits when attacking from higher ground.",
    tiers: [
      {
        name: "High Ground Controller (Tier 1)",
        description:
          "Add a d6 to your ranged attack from 10+ feet higher ground.",
        cost: 6,
        type: "general",
        tier: 1,
      },
      {
        name: "High Ground Controller (Tier 2)",
        description:
          "Add a d6 to your ranged attack and gain advantage when attacking from 10+ feet higher ground.",
        cost: 10,
        type: "general",
        tier: 2,
      },
    ],
  },
  {
    name: "Lucky Opportunist",
    description: "Gain the ability to act out of turn order using luck tokens.",
    tiers: [
      {
        name: "Lucky Opportunist (Tier 1)",
        description:
          "After any turn in turn order, use a luck token to act out of turn order. Either use your Maneuver or your Action (but not both).",
        cost: 6,
        type: "general",
        tier: 1,
      },
      {
        name: "Lucky Opportunist (Tier 2)",
        description:
          "After any turn in turn order, use a luck token to act out of turn order. You may use both your Maneuver and your Action.",
        cost: 8,
        type: "general",
        tier: 2,
      },
    ],
  },
  {
    name: "Magic Initiate",
    description: "You learn a spell of your choice.",
    tiers: [
      {
        name: "Magic Initiate (Tier 1)",
        description: "Learn a tier 1 spell.",
        cost: 8,
        type: "general",
        tier: 1,
      },
      {
        name: "Magic Initiate (Tier 2)",
        description: "Learn a tier 2 spell.",
        cost: 16,
        type: "general",
        tier: 2,
      },
      {
        name: "Magic Initiate (Tier 3)",
        description: "Learn a tier 3 spell.",
        cost: 24,
        type: "general",
        tier: 3,
      },
    ],
  },
  {
    name: "Motivating Commander",
    description: "You can command allies with a DC charisma check.",
    tiers: [
      {
        name: "Motivating Commander (Tier 1)",
        description:
          "DC 10 + (# of allies commanded) Charisma check to give a motivating command. Allies can use a Maneuver immediately.",
        cost: 6,
        type: "general",
        tier: 1,
      },
      {
        name: "Motivating Commander (Tier 2)",
        description:
          "Command allies without consuming your action. Allies can use a Maneuver immediately.",
        cost: 8,
        type: "general",
        tier: 2,
      },
    ],
  },
  {
    name: "Sentinel",
    description: "You can attack enemies that leave your reach.",
    tiers: [
      {
        name: "Sentinel (Tier 1)",
        description:
          "If an opponent who is close to you moves out of range, you may attack them with your melee weapon once between your turns.",
        cost: 8,
        type: "general",
        tier: 1,
      },
      {
        name: "Sentinel (Tier 2) - Tunnel Fighter",
        description:
          "If an opponent who is close to you moves out of range, you may attack them as many times as you want between turns.",
        cost: 12,
        type: "general",
        tier: 2,
      },
      {
        name: "Sentinel (Tier 3) - Tunnel Master",
        description:
          "If an opponent who is close to you moves out of range, you may attack them as many times as you want between turns. If you strike an opponent, they lose the rest of their Maneuver.",
        cost: 16,
        type: "general",
        tier: 3,
      },
    ],
  },
];

export const CLASS_BOONS: { [key: string]: LevelAbility[] } = {
  Fighter: [
    {
      name: "Class Talent",
      description: "Random roll on your class's talent table.",
      cost: 6,
      type: "class",
    },
  ],
  Priest: [
    {
      name: "Aura of Protection",
      description:
        "Allies near you (including yourself) add your charisma modifier to Wisdom Checks to fight against mental attacks.",
      cost: 8,
      type: "class",
    },
    {
      name: "Divine Smite",
      description:
        "Roll a DC 10 Wisdom check when performing a melee attack:\n- If you succeed, imbue your attack with 1d6 radiant damage.\n- You may increase the DC to add more d6s.",
      cost: 8,
      type: "class",
    },
  ],
  Thief: [
    {
      name: "Cunning Attack",
      description:
        "You can apply Backstab when an opponent is close to an ally and engaged in combat.",
      cost: 8,
      type: "class",
    },
  ],
  Wizard: [
    {
      name: "Controlled Wizard",
      description:
        "When rolling on the Wizard Mishap table, roll twice and choose the effect you want.",
      cost: 2,
      type: "class",
    },
    {
      name: "Elemental Adept (Tier 1)",
      description:
        "Choose one element (fire, ice, lightning, etc.). Reroll any 1s on damage dice when casting spells of that element.",
      cost: 4,
      type: "class",
    },
    {
      name: "Elemental Adept (Tier 2)",
      description: "Gain advantage on casting spells of your chosen element.",
      cost: 12,
      type: "class",
    },
  ],
};

export const RACES: LevelAbility[] = [
  {
    name: "Dwarf",
    description:
      "Gain the following traits:\n- Stone-cunning: Gain the Masonry skill\n- Language: You speak Dwarvish",
    cost: 2,
    type: "race",
    skills: ["Masonry"],
    languages: ["Dwarvish"],
  },
  {
    name: "Goblin",
    description:
      "Gain the following traits:\n- You speak Goblin\n- Tinkerer: Get a slot in your free to carry.\n- Pack Tactics: Advantage on attack rolls if ally is within 5ft of the enemy.\n- Natural Illusionist: Cast minor illusion as psionic ability.\n- Ugly: Disadvantage on charisma checks against the finer folk.\n- Cold Iron Weakness: Take damage from touching cold iron.",
    cost: 6,
    type: "race",
    languages: ["Goblin"],
  },
  {
    name: "Halfling",
    description:
      "Gain the following traits:\n- Small: Move through larger creatures’ spaces without contest.\n- The Hidden Folk: Turn invisible for 10 rounds between rests.\n- Hungry: Rations die decreases on 1, 2, or 3.\n- Language: You speak Silvin (halfling dialect).",
    cost: 8,
    type: "race",
    languages: ["Silvin"],
  },
  {
    name: "Human - Drune",
    description:
      "Gain the following traits:\n- Speak Drunic.\n- 60ft dark vision.\n- Summon flickering green flames.\n- Drune spell-casters may get cheaper flame spells (DM Fiat).",
    cost: 8,
    type: "race",
    languages: ["Drunic"],
  },
  {
    name: "Mouranth",
    description:
      "Gain the following traits:\n- Carapace: +1 bonus to AC.\n- Mouranth Munitions: Craft explosive munitions with smoke, fire, and frag grenades.",
    cost: 8,
    type: "race",
  },
  {
    name: "Aelf Liosan",
    description:
      "Gain the following traits:\n- Long-living: Elves live up to 500 years.\n- Fey-Ancestry: Advantage on saves against being charmed, immune to magic sleep.\n- Trance: Meditate for 4 hours for long rest.\n- Language: You speak Silvin (elf dialect).",
    cost: 8,
    type: "race",
    languages: ["Silvin"],
  },
  {
    name: "Aelf Andii (Dark Elf)",
    description:
      "Gain the following traits:\n- Darkvision: You can see in darkness within 120 feet as if it were dim light.\n- Shadowmeld: Once per day, you can become invisible in dim light or darkness until you move or attack.\n- Sunlight Sensitivity: You have disadvantage on attack rolls and Perception checks while in direct sunlight.\n- Language: You speak Aelf Andii (Dark Elf dialect).",
    cost: 8,
    type: "race",
    languages: ["Aelf Andii"],
  },
  {
    name: "Aelf Sylvan (Forest Elf)",
    description:
      "Gain the following traits:\n- Forest Stride: Move through natural difficult terrain without slowing down.\n- Keen Senses: You have proficiency in Perception checks and gain advantage in forest environments.\n- Nature Magic: You know the Druidcraft cantrip. Once per day, you can cast the spell Speak with Animals without using a spell slot.\n- Language: You speak Sylvan (Forest Elf dialect).",
    cost: 8,
    type: "race",
    languages: ["Sylvan (Forest Elf Dialect)"],
  },
];

export const BANES: LevelAbility[] = [];

export const SKILL_BOONS: LevelAbility[] = [
  // 2 CP (Rarely Comes Up)
  {
    name: "Astrology",
    description:
      "Gain the Astrology skill. You are skilled at interpreting the stars and predicting events based on celestial movements.",
    cost: 2,
    type: "skill",
    skills: ["Astrology"],
  },
  {
    name: "Brewing",
    description:
      "Gain the Brewing skill. You are skilled in making various alcoholic drinks.",
    cost: 2,
    type: "skill",
    skills: ["Brewing"],
  },
  {
    name: "Cooking",
    description:
      "Gain the Cooking skill. You are skilled at preparing meals, including gourmet or survivalist cooking.",
    cost: 2,
    type: "skill",
    skills: ["Cooking"],
  },
  {
    name: "Court Intrigue",
    description:
      "Gain the Court Intrigue skill. You understand the hidden power dynamics within noble courts.",
    cost: 2,
    type: "skill",
    skills: ["Court Intrigue"],
  },
  {
    name: "Fashion Knowledge",
    description:
      "Gain the Fashion Knowledge skill. You are well-versed in current and historical fashion trends.",
    cost: 2,
    type: "skill",
    skills: ["Fashion Knowledge"],
  },
  {
    name: "Heraldry",
    description:
      "Gain the Heraldry skill. You are knowledgeable about noble families, coats of arms, and official symbols.",
    cost: 2,
    type: "skill",
    skills: ["Heraldry"],
  },

  // 3 CP (Rare)
  {
    name: "Carpentry",
    description:
      "Gain the Carpentry skill. You are skilled in working with wood to build or repair structures and items.",
    cost: 3,
    type: "skill",
    skills: ["Carpentry"],
  },
  {
    name: "Calligraphy",
    description:
      "Gain the Calligraphy skill. You are skilled at creating beautiful, artistic writing.",
    cost: 3,
    type: "skill",
    skills: ["Calligraphy"],
  },
  {
    name: "Ghost Lore",
    description:
      "Gain the Ghost Lore skill. You have knowledge of ghosts, spirits, and hauntings.",
    cost: 3,
    type: "skill",
    skills: ["Ghost Lore"],
  },
  {
    name: "Poison Crafting",
    description:
      "Gain the Poison Crafting skill. You are adept at creating harmful poisons.",
    cost: 3,
    type: "skill",
    skills: ["Poison Crafting"],
  },
  {
    name: "Structural Assessment",
    description:
      "Gain the Structural Assessment skill. You are skilled at determining the integrity and safety of buildings and other structures.",
    cost: 3,
    type: "skill",
    skills: ["Structural Assessment"],
  },
  {
    name: "Veterinary",
    description:
      "Gain the Veterinary skill. You are skilled in diagnosing and treating animal injuries and diseases.",
    cost: 3,
    type: "skill",
    skills: ["Veterinary"],
  },

  // 5 CP (Somewhat Common)
  {
    name: "Alchemy",
    description:
      "Gain the Alchemy skill. You are skilled at creating potions, elixirs, and other chemical substances.",
    cost: 4,
    type: "skill",
    skills: ["Alchemy"],
  },
  {
    name: "Animal Tracking",
    description:
      "Gain the Animal Tracking skill. You are skilled at tracking animals and understanding their movements.",
    cost: 4,
    type: "skill",
    skills: ["Animal Tracking"],
  },
  {
    name: "Appraisal",
    description:
      "Gain the Appraisal skill. You are skilled at determining the value of items and treasures.",
    cost: 4,
    type: "skill",
    skills: ["Appraisal"],
  },
  {
    name: "Banter",
    description:
      "Gain the Banter skill. You are skilled at light-hearted conversation and maintaining social situations.",
    cost: 4,
    type: "skill",
    skills: ["Banter"],
  },
  {
    name: "Beast Lore",
    description:
      "Gain the Beast Lore skill. You are knowledgeable about beasts and monsters, including their habits and weaknesses.",
    cost: 4,
    type: "skill",
    skills: ["Beast Lore"],
  },
  {
    name: "Arcane Knowledge",
    description:
      "Gain the Arcane Knowledge skill. You are knowledgeable about magic, magical items, and arcane lore.",
    cost: 5,
    type: "skill",
    skills: ["Arcane Knowledge"],
  },
  {
    name: "Athletics",
    description:
      "Gain the Athletics skill. You excel at physical activities like climbing, jumping, and swimming.",
    cost: 5,
    type: "skill",
    skills: ["Athletics"],
  },
  {
    name: "Brawling",
    description:
      "Gain the Brawling skill. You are skilled in unarmed combat and fighting without weapons.",
    cost: 5,
    type: "skill",
    skills: ["Brawling"],
  },
  {
    name: "Brute Force",
    description:
      "Gain the Brute Force skill. You are adept at using raw strength to overcome physical obstacles.",
    cost: 5,
    type: "skill",
    skills: ["Brute Force"],
  },
  {
    name: "Crafting",
    description:
      "Gain the Crafting skill. You are skilled at creating or repairing items using various materials.",
    cost: 5,
    type: "skill",
    skills: ["Crafting"],
  },
  {
    name: "Disguise",
    description:
      "Gain the Disguise skill. You are adept at altering your appearance to pass as someone else.",
    cost: 5,
    type: "skill",
    skills: ["Disguise"],
  },
  {
    name: "Escape Artist",
    description:
      "Gain the Escape Artist skill. You are skilled at escaping from bonds, traps, and confined spaces.",
    cost: 5,
    type: "skill",
    skills: ["Escape Artist"],
  },
  {
    name: "Foraging",
    description:
      "Gain the Foraging skill. You are skilled at finding food and useful plants in the wilderness.",
    cost: 5,
    type: "skill",
    skills: ["Foraging"],
  },
  {
    name: "Geography",
    description:
      "Gain the Geography skill. You are knowledgeable about the physical features of lands and regions.",
    cost: 5,
    type: "skill",
    skills: ["Geography"],
  },
  {
    name: "Investigation",
    description:
      "Gain the Investigation skill. You excel at finding clues and solving puzzles.",
    cost: 5,
    type: "skill",
    skills: ["Investigation"],
  },
  {
    name: "Local Knowledge",
    description:
      "Gain the Local Knowledge skill. You are familiar with the customs, laws, and important figures in a particular area.",
    cost: 5,
    type: "skill",
    skills: ["Local Knowledge"],
  },
  {
    name: "Logistics",
    description:
      "Gain the Logistics skill. You are skilled at managing supplies, transportation, and planning.",
    cost: 5,
    type: "skill",
    skills: ["Logistics"],
  },
  {
    name: "Performance",
    description:
      "Gain the Performance skill. You excel in entertaining others with music, acting, or other forms of art.",
    cost: 5,
    type: "skill",
    skills: ["Performance"],
  },
  {
    name: "Research",
    description:
      "Gain the Research skill. You excel at finding information and answers through study and inquiry.",
    cost: 5,
    type: "skill",
    skills: ["Research"],
  },
  {
    name: "Sleight of Hand",
    description:
      "Gain the Sleight of Hand skill. You excel at tricks of manual dexterity, such as picking pockets or hiding objects.",
    cost: 5,
    type: "skill",
    skills: ["Sleight of Hand"],
  },
  {
    name: "Urban Navigation",
    description:
      "Gain the Urban Navigation skill. You are skilled at finding your way through cities and urban environments.",
    cost: 5,
    type: "skill",
    skills: ["Urban Navigation"],
  },
  {
    name: "Weapon Knowledge",
    description:
      "Gain the Weapon Knowledge skill. You are skilled in identifying and understanding various weapons.",
    cost: 5,
    type: "skill",
    skills: ["Weapon Knowledge"],
  },
  {
    name: "Nature",
    description:
      "Gain the Nature skill. You have a deep understanding of plants, animals, and natural phenomena.",
    cost: 5,
    type: "skill",
    skills: ["Nature"],
  },

  // 6 CP (Very Useful)
  {
    name: "Animal Handling",
    description:
      "Gain the Animal Handling skill. You are skilled at training, calming, and working with animals.",
    cost: 6,
    type: "skill",
    skills: ["Animal Handling"],
  },
  {
    name: "Deception",
    description:
      "Gain the Deception skill. You are skilled at lying and misleading others without being caught.",
    cost: 6,
    type: "skill",
    skills: ["Deception"],
  },
  {
    name: "Climbing",
    description:
      "Gain the Climbing skill. You are skilled at scaling walls, cliffs, and other vertical surfaces.",
    cost: 6,
    type: "skill",
    skills: ["Climbing"],
  },
  {
    name: "Insight",
    description:
      "Gain the Insight skill. You excel at reading people and sensing their motives.",
    cost: 6,
    type: "skill",
    skills: ["Insight"],
  },
  {
    name: "Intimidation",
    description:
      "Gain the Intimidation skill. You are skilled at using fear and force to get what you want.",
    cost: 6,
    type: "skill",
    skills: ["Intimidation"],
  },
  {
    name: "Medicine",
    description:
      "Gain the Medicine skill. You are skilled at diagnosing and treating injuries and illnesses.",
    cost: 6,
    type: "skill",
    skills: ["Medicine"],
  },
  {
    name: "Persuasion",
    description:
      "Gain the Persuasion skill. You excel at convincing and influencing others.",
    cost: 6,
    type: "skill",
    skills: ["Persuasion"],
  },
  {
    name: "Perception",
    description:
      "Gain the Perception skill. You are adept at noticing details and spotting hidden things.",
    cost: 6,
    type: "skill",
    skills: ["Perception"],
  },
  {
    name: "Stealth",
    description:
      "Gain the Stealth skill. You excel at moving silently and avoiding detection.",
    cost: 6,
    type: "skill",
    skills: ["Stealth"],
  },
  {
    name: "Survival",
    description:
      "Gain the Survival skill. You are skilled at surviving in the wild, tracking creatures, and finding food and water.",
    cost: 6,
    type: "skill",
    skills: ["Survival"],
  },
  {
    name: "Interrogation",
    description:
      "Gain the Interrogation skill. You are skilled at extracting information from individuals through questioning.",
    cost: 6,
    type: "skill",
    skills: ["Interrogation"],
  },
  {
    name: "Lockpicking",
    description:
      "Gain the Lockpicking skill. You are skilled at unlocking doors, chests, and other objects using thieves' tools.",
    cost: 6,
    type: "skill",
    skills: ["Lockpicking"],
  },
  {
    name: "Mountaineering",
    description:
      "Gain the Mountaineering skill. You are skilled at traversing difficult mountainous terrain.",
    cost: 5,
    type: "skill",
    skills: ["Mountaineering"],
  },
  {
    name: "Sailing",
    description:
      "Gain the Sailing skill. You are skilled at operating and navigating watercraft in rivers, lakes, or open seas.",
    cost: 6,
    type: "skill",
    skills: ["Sailing"],
  },
  {
    name: "Trap Disarming",
    description:
      "Gain the Trap Disarming skill. You are skilled at identifying and safely disabling traps.",
    cost: 6,
    type: "skill",
    skills: ["Trap Disarming"],
  },
];

export const LANGUAGE_BOONS: TieredAbility[] = [
  {
    name: "Sylvan",
    description: "Learn the Aelph Sylvan language.",
    tiers: [
      {
        name: "Sylvan (Broken)",
        description:
          "Gain a broken understanding of the Aelph Sylvan language.",
        cost: 1,
        type: "language",
        tier: 1,
        languages: ["Sylvan (Broken)"],
      },
      {
        name: "Sylvan (Accented)",
        description:
          "Improve your Aelph Sylvan to an accented level of fluency.",
        cost: 2,
        type: "language",
        tier: 2,
        languages: ["Sylvan (Accented)"],
      },
      {
        name: "Sylvan (Fluent)",
        description: "Master the Aelph Sylvan language, speaking it fluently.",
        cost: 3,
        type: "language",
        tier: 3,
        languages: ["Sylvan (Fluent)"],
      },
    ],
  },
  {
    name: "Goblin",
    description: "Learn the Goblin language.",
    tiers: [
      {
        name: "Goblin (Broken)",
        description: "Gain a broken understanding of the Goblin language.",
        cost: 1,
        type: "language",
        tier: 1,
        languages: ["Goblin (Broken)"],
      },
      {
        name: "Goblin (Accented)",
        description: "Improve your Goblin to an accented level of fluency.",
        cost: 2,
        type: "language",
        tier: 2,
        languages: ["Goblin (Accented)"],
      },
      {
        name: "Goblin (Fluent)",
        description: "Master the Goblin language, speaking it fluently.",
        cost: 3,
        type: "language",
        tier: 3,
        languages: ["Goblin (Fluent)"],
      },
    ],
  },
  {
    name: "Drunic",
    description: "Learn the Drunic language.",
    tiers: [
      {
        name: "Drunic (Broken)",
        description: "Gain a broken understanding of the Drunic language.",
        cost: 1,
        type: "language",
        tier: 1,
        languages: ["Drunic (Broken)"],
      },
      {
        name: "Drunic (Accented)",
        description: "Improve your Drunic to an accented level of fluency.",
        cost: 2,
        type: "language",
        tier: 2,
        languages: ["Drunic (Accented)"],
      },
      {
        name: "Drunic (Fluent)",
        description: "Master the Drunic language, speaking it fluently.",
        cost: 3,
        type: "language",
        tier: 3,
        languages: ["Drunic (Fluent)"],
      },
    ],
  },
  {
    name: "Mouranth",
    description: "Learn the Mouranth language.",
    tiers: [
      {
        name: "Mouranth (Broken)",
        description: "Gain a broken understanding of the Mouranth language.",
        cost: 1,
        type: "language",
        tier: 1,
        languages: ["Mouranth (Broken)"],
      },
      {
        name: "Mouranth (Accented)",
        description: "Improve your Mouranth to an accented level of fluency.",
        cost: 2,
        type: "language",
        tier: 2,
        languages: ["Mouranth (Accented)"],
      },
      {
        name: "Mouranth (Fluent)",
        description: "Master the Mouranth language, speaking it fluently.",
        cost: 3,
        type: "language",
        tier: 3,
        languages: ["Mouranth (Fluent)"],
      },
    ],
  },
  {
    name: "Halfling",
    description: "Learn the Halfling dialect of Sylvan.",
    tiers: [
      {
        name: "Halfling (Broken)",
        description:
          "Gain a broken understanding of the Halfling dialect (Silvin).",
        cost: 1,
        type: "language",
        tier: 1,
        languages: ["Silvin (Halfling Broken)"],
      },
      {
        name: "Halfling (Accented)",
        description:
          "Improve your Silvin (Halfling dialect) to an accented level of fluency.",
        cost: 2,
        type: "language",
        tier: 2,
        languages: ["Silvin (Halfling Accented)"],
      },
      {
        name: "Halfling (Fluent)",
        description:
          "Master the Halfling dialect of Silvin, speaking it fluently.",
        cost: 3,
        type: "language",
        tier: 3,
        languages: ["Silvin (Halfling Fluent)"],
      },
    ],
  },
  {
    name: "Aelf Andii",
    description: "Learn the Aelf Andii language.",
    tiers: [
      {
        name: "Aelf Andii (Broken)",
        description: "Gain a broken understanding of the Aelf Andii language.",
        cost: 1,
        type: "language",
        tier: 1,
        languages: ["Aelf Andii (Broken)"],
      },
      {
        name: "Aelf Andii (Accented)",
        description: "Improve your Aelf Andii to an accented level of fluency.",
        cost: 2,
        type: "language",
        tier: 2,
        languages: ["Aelf Andii (Accented)"],
      },
      {
        name: "Aelf Andii (Fluent)",
        description: "Master the Aelf Andii language, speaking it fluently.",
        cost: 3,
        type: "language",
        tier: 3,
        languages: ["Aelf Andii (Fluent)"],
      },
    ],
  },
];
