import { BACKGROUNDS } from "../data/background";
import { CharacterClass } from "./class-types";
import { isPresent } from "../utils/utility";
import {
  Armor,
  AttackRange,
  Weapon,
  WeaponProperties,
  Roll,
} from "./inventory-types";
import { WeaponType, ArmorType, Item } from "./inventory-types";
import { StatValue } from "../components/terminal-entry/stat-allocation/StatAllocation";
import { Ability } from "./ability-types";

export interface Condition {
  name: string;
  description: string;
}

export interface Attack {
  name: string;
  description: string;
  damage: Roll;
  range: AttackRange;
  properties: WeaponProperties[];
}

export const isValidStat = (stat: string): stat is Stat => {
  return ["str", "dex", "cha", "con", "wis"].includes(stat);
};

export type Stat = "str" | "dex" | "cha" | "con" | "wis";

export interface Character {
  name: string;
  class: string;
  gender: string;
  description: string;
  ac: number;
  stats: {
    [key in Stat]: number;
  };
  race: string;
  background: string;
  skills: string[];
  level: number;
  character_points: {
    used: number;
    total: number;
  };
  hp: {
    current: number;
    max: number;
    perlevel: number;
  };
  proficiencies: (WeaponType | ArmorType)[];
  languages: string[];
  attacks: Attack[];
  abilities: Ability[];
  conditions: string[];
}

export const setCharacterClass = (
  character: Character,
  charClass: CharacterClass
): Character => ({
  ...character,
  class: charClass.name,
  hp: {
    ...character.hp,
    perlevel: charClass.hpPerLevel,
  },
  proficiencies: charClass.proficiency,
  abilities: charClass.abilities,
});

export const setCharacterBackground = (
  character: Character,
  selectedBackground: string
): Character => {
  const backgroundName = selectedBackground;
  const background = BACKGROUNDS.find((bg) => bg.name === backgroundName);
  if (isPresent(background)) {
    character.background = backgroundName;
    character.skills = background.skills;
  } else {
    console.error(`Invalid background: ${backgroundName}`);
  }
  return character;
};

export const setCharacterStats = (
  character: Character,
  stats: StatValue[],
  inventory: Item[]
): Character => {
  const newCharacter = { ...character };
  newCharacter.stats = {
    str: stats.find((stat) => stat.name === "str")?.value || 0,
    dex: stats.find((stat) => stat.name === "dex")?.value || 0,
    cha: stats.find((stat) => stat.name === "cha")?.value || 0,
    con: stats.find((stat) => stat.name === "con")?.value || 0,
    wis: stats.find((stat) => stat.name === "wis")?.value || 0,
  };

  newCharacter.hp = updateMaxHp(newCharacter);
  console.log("HP", newCharacter.hp);
  newCharacter.ac = setCharacterAC(newCharacter, inventory);
  newCharacter.attacks = setCharacterAttacks(newCharacter, inventory);

  return newCharacter;
};

export const updateMaxHp = (
  character: Character
): { current: number; max: number; perlevel: number } => {
  const newMaxHp =
    character.hp.perlevel * character.level + character.stats.con;
  const hpIncrease = newMaxHp - character.hp.max;
  return {
    current: Math.min(character.hp.current + hpIncrease, newMaxHp),
    max: newMaxHp,
    perlevel: character.hp.perlevel,
  };
};

export const setCharacterAC = (
  character: Character,
  inventory: Item[]
): number => {
  const armorItems = inventory.filter(
    (item): item is Armor => item.type === "Armor" && item.equipped
  );

  let baseAC = 10;
  let hasDexMod = true;
  let hasHalfDexMod = false;

  for (const armor of armorItems) {
    baseAC += armor.ac;
    if (armor.modifier === "None") {
      hasDexMod = false;
      hasHalfDexMod = false;
      break;
    } else if (armor.modifier === "Dex/2") {
      hasHalfDexMod = true;
    }
  }

  if (hasDexMod) {
    baseAC += character.stats.dex;
  } else if (hasHalfDexMod) {
    baseAC += Math.floor(character.stats.dex / 2);
  }

  return baseAC;
};

const setCharacterAttacks = (
  character: Character,
  inventory: Item[]
): Attack[] => {
  const attacks: Attack[] = [];

  if (character.stats.str >= 0) {
    attacks.push({
      name: "Unarmed Strike",
      description: "A basic melee attack using your fists or body.",
      damage: [1 + character.stats.str],
      range: "Close",
      properties: [],
    });
  }

  const equippedWeapons = inventory.filter(
    (item): item is Weapon => item.type === "Weapon" && item.equipped
  );

  for (const weapon of equippedWeapons) {
    let damage = weapon.damage;

    attacks.push({
      name: weapon.name,
      description: weapon.description,
      damage: damage,
      range: weapon.range,
      properties: weapon.properties || [],
    });
  }

  return attacks;
};

export const initializeCharacter = (): Character => ({
  name: "Unknown",
  class: "",
  gender: "Male",
  description: "",
  ac: 10,
  stats: {
    str: 0,
    dex: 0,
    cha: 0,
    con: 0,
    wis: 0,
  },
  race: "Human",
  background: "",
  skills: [],
  level: 1,
  character_points: {
    used: 0,
    total: 0,
  },
  hp: {
    current: 0,
    max: 0,
    perlevel: 0,
  },
  proficiencies: [],
  languages: ["Common"],
  attacks: [],
  abilities: [],
  conditions: [],
});

export const DEFAULT_CHARACTER: Character = {
  name: "Adrian",
  class: "Fighter",
  gender: "Male",
  description:
    "Adrian is a handsome, tan-skinned male standing 6'1\" tall, with blonde hair and a strong jaw accentuated by a prominent broken nose. He possesses a lean and muscular physique, highlighting his athleticism.",
  ac: 15,
  stats: {
    str: 4,
    dex: 0,
    cha: 2,
    con: 2,
    wis: -2,
  },
  race: "Human",
  background: "City Guard",
  skills: ["Investigation", "Intimidation", "Local Laws"],
  level: 1,
  character_points: {
    used: 0,
    total: 0,
  },
  hp: {
    current: 7,
    max: 7,
    perlevel: 5,
  },
  proficiencies: [
    "Martial Weapon",
    "Simple Weapon",
    "Shield",
    "Heavy Armor",
    "Medium Armor",
    "Light Armor",
  ],
  languages: ["Common"],
  attacks: [
    {
      name: "Unarmed Strike",
      description: "A basic melee attack using your fists or body.",
      damage: [5],
      range: "Close",
      properties: [],
    },
    {
      name: "Longsword",
      description:
        "A well-worn blade with a keen edge, perfect for dealing heavy blows in close combat.",
      damage: [{ sides: 8 }],
      range: "Close",
      properties: [],
    },
  ],
  abilities: [
    {
      name: "Hauler",
      description:
        "Add your Constitution modifier, if positive, to your gear slots.",
    },
    {
      name: "Weapon Mastery",
      description:
        "Choose one type of weapon, such as longswords. You gain +1 to attack and damage with that weapon type. In addition, add half your level to these rolls (round down).",
    },
    {
      name: "Grit",
      description:
        "Choose Strength or Dexterity. You have advantage on checks of that type to overcome an opposing force, such as kicking open a stuck door (Strength) or slipping free of rusty chains (Dexterity).",
    },
  ],
  conditions: [],
};
