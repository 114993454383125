import React, { useState } from "react";
import { Item, Weapon, Armor } from "../../../types/inventory-types";

interface InventoryProps {
  inventory: Item[];
}

export const Inventory: React.FC<InventoryProps> = ({ inventory }) => {
  const [expandedItem, setExpandedItem] = useState<string | null>(null);

  const toggleItem = (itemName: string) => {
    if (expandedItem === itemName) {
      setExpandedItem(null);
    } else {
      setExpandedItem(itemName);
    }
  };

  const isWeapon = (item: Item): item is Weapon => {
    return item.type === "Weapon";
  };

  const isArmor = (item: Item): item is Armor => {
    return item.type === "Armor";
  };

  const formatDamage = (item: Weapon) => {
    return `${item.damage}`; // FIX
  };

  return (
    <div className="side-panel">
      <h2>Inventory</h2>
      <p>
        <strong>Slots: </strong>
        {inventory.length}/{20}
      </p>
      <ul>
        {inventory.map((item, index) => (
          <li key={index} onClick={() => toggleItem(item.name)}>
            <div className="flex justify-between bg-secondary p-2 rounded cursor-pointer">
              <span>{item.name}</span>
            </div>
            {expandedItem === item.name && (
              <div className="text-details">
                <em>{item.description}</em>
                <p>
                  <b>Type:</b> {item.type}
                </p>
                <p>
                  <b>Slots:</b> {item.slots}
                </p>
                <p>
                  <b>Value:</b> {item.value}
                </p>
                {isWeapon(item) && (
                  <>
                    <p>
                      <b>Damage:</b> {formatDamage(item)}
                    </p>
                    <p>
                      <b>Range:</b> {item.range}
                    </p>
                  </>
                )}
                {isArmor(item) && (
                  <>
                    <p>
                      <b>AC:</b> +{item.ac}
                    </p>
                    <p>
                      <b>Armor Type:</b> {item.armorType}
                    </p>
                  </>
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
