import React, { useState } from "react";
import { Character } from "../../../types/character-types";

export interface ConsoleModalProps {
  character: Character;
  updateCharacter: (key: keyof Character, value: any) => void;
}

export const ConsoleModal: React.FC<ConsoleModalProps> = ({
  character,
  updateCharacter,
}) => {
  const [command, setCommand] = useState("");

  const handleCommand = () => {
    const [action, ...params] = command.toLowerCase().split(" ");

    switch (action) {
      case "level":
        const levelIncrease = parseInt(params[0]);
        if (!isNaN(levelIncrease)) {
          const newLevel = character.level + levelIncrease;
          const newTotalCP =
            character.character_points.total + 10 * levelIncrease;
          updateCharacter("level", newLevel);
          updateCharacter("character_points", {
            ...character.character_points,
            total: newTotalCP,
          });
        }
        break;
      default:
        console.log("Unknown command");
    }
    setCommand("");
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4 text-black">Console</h2>
      <input
        type="text"
        value={command}
        onChange={(e) => setCommand(e.target.value)}
        onKeyDown={(e) => e.key === "Enter" && handleCommand()}
        className="w-full p-2 border rounded"
        placeholder="Enter command (e.g., 'level 5')"
      />
    </div>
  );
};
