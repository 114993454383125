import React, { useState } from "react";
import { Character } from "../../../types/character-types";
import { Modal } from "../../modal/Modal";
import { LevelAbility } from "../../../types/ability-types";

interface CharacterSheetProps {
  character: Character;
  updateCharacter: (key: keyof Character, value: any) => void;
}

export const CharacterSheet: React.FC<CharacterSheetProps> = ({
  character,
  updateCharacter,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const formatStat = (stat: number) => {
    return stat >= 0 ? `+${stat}` : stat;
  };

  const statWidget = (statValue: number, statName: string) => {
    return (
      <div className="text-center">
        <p>{statName.toUpperCase()}</p>
        <p>{formatStat(statValue)}</p>
      </div>
    );
  };

  return (
    <div className="side-panel">
      <h2>{character.name}</h2>
      <p>Class: {character.class}</p>
      <p>Background: {character.background}</p>
      <p>Race: {character.race}</p>
      <p>Level: {character.level}</p>
      <p>
        HP: {character.hp.current}/{character.hp.max}
      </p>
      <p>AC: {character.ac}</p>
      <h3>Stats</h3>
      <div className="flex justify-between">
        {Object.entries(character.stats).map(([statName, statValue]) =>
          statWidget(statValue, statName)
        )}
      </div>
      <h3>Skills</h3>
      <ul>
        {character.skills.map((skill, index) => (
          <li key={index}>{skill}</li>
        ))}
      </ul>
      <h3>Abilities</h3>
      <ul>
        {character.abilities.map((ability, index) => (
          <li key={index}>
            <strong>{ability.name}:</strong> {ability.description}
          </li>
        ))}
      </ul>
      <h3>Description</h3>
      <p>{character.description}</p>
      <h3>Languages</h3>
      <ul className="list-none pl-0">
        {character.languages.map((language, index) => (
          <li key={index}>{language}</li>
        ))}
      </ul>
      <h3>Leveling</h3>
      <p>Level: {character.level}</p>
      <p>
        Character Points:{" "}
        {character.character_points.total - character.character_points.used}/
        {character.character_points.total}
      </p>
      <div className="flex justify-center mt-4">
        <button className="button" onClick={() => setIsModalOpen(true)}>
          Level Up
        </button>
      </div>
      {isModalOpen ? (
        <Modal
          levelUpModalProps={{
            character: character,
            updateCharacter: updateCharacter,
          }}
          onClose={closeModal}
        ></Modal>
      ) : undefined}
    </div>
  );
};
