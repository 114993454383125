import { useState } from "react";
import { ArrowUp, ArrowDown } from "lucide-react";
import { Stat } from "../../../types/character-types";

export interface StatValue {
  name: Stat;
  value: number;
}

export interface StatAllocationProps {
  onComplete: (stats: StatValue[]) => void;
}

export const StatAllocation: React.FC<StatAllocationProps> = ({
  onComplete,
}) => {
  const [budget, setBudget] = useState(6);
  const [stats, setStats] = useState<StatValue[]>([
    { name: "str", value: 0 },
    { name: "dex", value: 0 },
    { name: "cha", value: 0 },
    { name: "con", value: 0 },
    { name: "wis", value: 0 },
  ]);
  const [isCompleted, setIsCompleted] = useState(false);

  const handleComplete = () => {
    onComplete(stats);
    setIsCompleted(true);
  };

  const handleStatChange = (index: number, change: number) => {
    if (isCompleted) return;
    if (budget - change < 0 || budget - change > 6) return;
    if (stats[index].value + change < -3 || stats[index].value + change > 5)
      return;

    setStats((prevStats) => {
      const newStats = [...prevStats];
      newStats[index] = {
        ...newStats[index],
        value: newStats[index].value + change,
      };
      return newStats;
    });
    setBudget((prevBudget) => prevBudget - change);
  };

  return (
    <div className="flex flex-col col items-center p-4 bg-secondary rounded">
      <div className="w-full mb-4">Remaining Points: {budget}</div>
      <div className="flex justify-between w-full flex-wrap">
        {stats.map((stat, index) => (
          <div
            key={stat.name}
            className="flex flex-col items-center p-2 m-2 min-w-[60px] flex-1"
          >
            <ArrowUp
              className={`cursor-pointer ${
                isCompleted ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => handleStatChange(index, 1)}
            />
            <div className="text-2xl my-1">
              {stat.value >= 0 ? `+${stat.value}` : stat.value}
            </div>
            <div className="uppercase">{stat.name.toUpperCase()}</div>
            <ArrowDown
              className={`cursor-pointer ${
                isCompleted ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => handleStatChange(index, -1)}
            />
          </div>
        ))}
      </div>
      {!isCompleted && (
        <button
          className={`button bg-black hover:bg-primary ${
            budget > 0 ? "cursor-not-allowed" : ""
          }`}
          onClick={handleComplete}
          disabled={budget !== 0}
        >
          Complete
        </button>
      )}
    </div>
  );
};
