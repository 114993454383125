import React from "react";
import { ClassModal, ClassModalProps } from "./class-modal/ClassModal";
import {
  BackgroundModal,
  BackgroundModalProps,
} from "./background-modal/BackgroundModal";
import { isPresent } from "../../utils/utility";
import { LevelUpModal, LevelUpModalProps } from "./level-up-modal/LevelUpModal";
import { ConsoleModal, ConsoleModalProps } from "./console-modal/ConsoleModal";
import {
  CharacterSheetModal,
  CharacterSheetModalProps,
} from "./character-sheet-modal/CharacterSheetModal";

interface ModalProps {
  onClose: () => void;
  onSelect?: () => void;
  classModalProps?: ClassModalProps;
  backgroundModalProps?: BackgroundModalProps;
  levelUpModalProps?: LevelUpModalProps;
  consoleModalProps?: ConsoleModalProps;
  characterSheetModalProps?: CharacterSheetModalProps;
}

export const Modal: React.FC<ModalProps> = ({
  onClose,
  onSelect,
  classModalProps,
  backgroundModalProps,
  levelUpModalProps,
  consoleModalProps,
  characterSheetModalProps,
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white text-black p-8 w-4/5 max-w-4xl rounded-lg max-h-[90vh] overflow-y-auto">
        {isPresent(classModalProps) ? (
          <ClassModal
            characterClass={classModalProps.characterClass}
          ></ClassModal>
        ) : undefined}
        {isPresent(backgroundModalProps) ? (
          <BackgroundModal
            characterBackground={backgroundModalProps.characterBackground}
          ></BackgroundModal>
        ) : undefined}
        {isPresent(levelUpModalProps) ? (
          <LevelUpModal
            character={levelUpModalProps.character}
            updateCharacter={levelUpModalProps.updateCharacter}
          ></LevelUpModal>
        ) : undefined}
        {isPresent(consoleModalProps) && (
          <ConsoleModal
            character={consoleModalProps.character}
            updateCharacter={consoleModalProps.updateCharacter}
          ></ConsoleModal>
        )}
        {isPresent(characterSheetModalProps) && (
          <CharacterSheetModal
            character={characterSheetModalProps.character}
          ></CharacterSheetModal>
        )}
        <div className="flex justify-end mt-6 space-x-4">
          {isPresent(onSelect) ? (
            <button className="button" onClick={onSelect}>
              Select
            </button>
          ) : undefined}
          <button className="button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
