import React, { useState } from "react";
import DOMPurify from "dompurify";
import { Modal } from "../modal/Modal";
import { CLASSES } from "../../types/class-types";
import { BACKGROUNDS } from "../../data/background";
import {
  StatValue,
  StatAllocation,
  StatAllocationProps,
} from "./stat-allocation/StatAllocation";
import { DiceRoll, DiceRollProps } from "./dice-roll/DiceRoll";
import { Stat } from "../../types/character-types";
import { isPresent } from "../../utils/utility";
import {
  ModalSelector,
  ModalSelectorProps,
} from "./modal-selector/ModalSelector";

export interface TerminalEntryProps {
  content: string;
  role: "user" | "assistant";
  type?:
    | "classSelection"
    | "backgroundSelection"
    | "statAllocation"
    | "diceRoll";
  modalSelectorProps?: ModalSelectorProps;
  statAllocationProps?: StatAllocationProps;
  diceRollProps?: DiceRollProps;
}

export const TerminalEntry: React.FC<TerminalEntryProps> = ({
  content: text,
  role,
  type,
  modalSelectorProps,
  statAllocationProps,
  diceRollProps,
}) => {
  const formatText = (inputText: string, role: "user" | "assistant") => {
    const formattedText = role === "user" ? `&gt; ${inputText}` : inputText;
    return DOMPurify.sanitize(formattedText, {
      ALLOWED_TAGS: ["b", "i", "em", "strong"],
    });
  };

  return (
    <div
      className={`p-2 text-xl whitespace-pre-wrap rounded mb-2 ${
        role === "user" ? "bg-secondary" : "bg-background"
      }`}
    >
      {(type === "classSelection" || type === "backgroundSelection") &&
      isPresent(modalSelectorProps) ? (
        <div>
          <p>{text}</p>
          <ModalSelector {...modalSelectorProps} />
        </div>
      ) : type === "statAllocation" && isPresent(statAllocationProps) ? (
        <div className="w-full">
          <p>{text}</p>
          <StatAllocation {...statAllocationProps} />
        </div>
      ) : type === "diceRoll" && isPresent(diceRollProps) ? (
        <div>
          <DiceRoll {...diceRollProps} />
        </div>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: formatText(text, role) }} />
      )}
    </div>
  );
};
