import React, { useCallback, useEffect, useState } from "react";
import { Character } from "../../types/character-types";
import { Item } from "../../types/inventory-types";
import { CharacterSheet } from "./character-sheet/CharacterSheet";
import { Inventory } from "./inventory/Inventory";
import { WorldMap } from "./map/WorldMap";

interface SidePanelProps {
  width: number;
  setWidth: (width: number) => void;
  imageUrl: string;
  character: Character;
  inventory: Item[];
  updateCharacter: (key: keyof Character, value: any) => void;
  onUndo: () => void;
}

export const SidePanel: React.FC<SidePanelProps> = ({
  width,
  setWidth,
  imageUrl,
  character,
  inventory,
  updateCharacter,
  onUndo,
}) => {
  const [activeTab, setActiveTab] = useState<
    "character" | "inventory" | "map" | "quests"
  >("character");
  const [isResizing, setIsResizing] = useState(false);

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsResizing(true);
  };

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isResizing) {
        const newWidth = window.innerWidth - e.clientX;
        setWidth(Math.max(300, Math.min(600, newWidth)));
      }
    },
    [isResizing, setWidth]
  );

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  useEffect(() => {
    const mouseMoveHandler = (e: MouseEvent) => handleMouseMove(e);
    const mouseUpHandler = () => handleMouseUp();

    if (isResizing) {
      window.addEventListener("mousemove", mouseMoveHandler);
      window.addEventListener("mouseup", mouseUpHandler);
    }

    return () => {
      window.removeEventListener("mousemove", mouseMoveHandler);
      window.removeEventListener("mouseup", mouseUpHandler);
    };
  }, [isResizing, handleMouseMove, handleMouseUp]);

  return (
    <div
      className="relative h-screen bg-primary overflow-hidden transition-all duration-200"
      style={{ width: `${width}px` }}
    >
      <div
        className="absolute left-0 top-0 bottom-0 w-2 cursor-ew-resize bg-secondary z-10"
        onMouseDown={handleMouseDown}
      ></div>
      <div className="flex flex-col h-full pl-2">
        <div className="pb-[100%] relative">
          <img
            src={imageUrl}
            alt="Generated"
            className="absolute max-h-[50vh]"
          />
        </div>
        <div className="flex-grow flex flex-col overflow-y-auto h-full">
          <div className="flex justify-around p-2 bg-secondary">
            <button
              className={`border-none text-text p-2 cursor-pointer transition-colors duration-300 text-lg ${
                activeTab === "character" ? "bg-tertiary" : ""
              }`}
              onClick={() => setActiveTab("character")}
            >
              Character
            </button>
            <button
              className={`border-none text-text p-2 cursor-pointer transition-colors duration-300 text-lg ${
                activeTab === "inventory" ? "bg-tertiary" : ""
              }`}
              onClick={() => setActiveTab("inventory")}
            >
              Inventory
            </button>
            <button
              className={`border-none text-text p-2 cursor-pointer transition-colors duration-300 text-lg ${
                activeTab === "map" ? "bg-tertiary" : ""
              }`}
              onClick={() => setActiveTab("map")}
            >
              Map
            </button>
            <button
              className={`border-none text-text p-2 cursor-pointer transition-colors duration-300 text-lg ${
                activeTab === "quests" ? "bg-tertiary" : ""
              }`}
              onClick={() => setActiveTab("quests")}
            >
              Quests
            </button>
          </div>
          <div className="mt-2 p-2 pt-0 text-lg flex-grow overflow-y-scroll">
            {activeTab === "character" && (
              <CharacterSheet
                character={character}
                updateCharacter={updateCharacter}
              />
            )}
            {activeTab === "inventory" && <Inventory inventory={inventory} />}
            {activeTab === "map" && <WorldMap />}
          </div>
          <div className="sticky bottom-0 w-full bg-secondary p-2 border-t border-tertiary">
            <button
              onClick={onUndo}
              className="w-full px-4 py-2 bg-tertiary text-text hover:bg-primary transition-colors duration-200 rounded"
            >
              Undo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
