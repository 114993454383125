import { World } from "../types/game-types";
export interface MessageStringParam {
  content: string;
  role: "user" | "assistant";
}

export const handleUserInputForGame = (
  userInput: string,
  entries: any[],
  setEntries: (entries: any[]) => void,
  setIsStreaming: (streaming: boolean) => void,
  socket: WebSocket | null,
  MAX_CHAT_LENGTH: number,
  isPresent: (value: any) => boolean
) => {
  const newEntries = [
    ...entries,
    { role: "user", content: userInput },
    { role: "assistant", content: "" },
  ];
  setEntries(newEntries);
  setIsStreaming(true);
  console.log("User message sent:", userInput);
  const webSocketMessages = [...entries, { role: "user", content: userInput }]
    .slice(-MAX_CHAT_LENGTH)
    .map(({ role, content }) => ({ role, content }));

  if (isPresent(socket)) {
    console.log("WebSocket message sent:", webSocketMessages);
    socket?.send(JSON.stringify({ messages: webSocketMessages }));
  }
};

export const initializeWorld = (): World => ({
  name: "",
  description: "",
  currentPOI: undefined,
  currentHex: { x: 0, y: 0 },
});
